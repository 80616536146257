<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      class="d-inline-block"
    >
      <div>
        <!-- Name -->
        <b-card-text class="mt-2 mb-2 h4 color-inherit text-reset text-center">
          {{$t('campaigns.briefDetail')}}
        </b-card-text>
        <div
          class="text-center"
          style="width: 100%; display: flex;"
        >
          <div
            style="width: 50%;"
          >
            <h5>{{$t('campaigns.applicantName')}}:</h5>
            <h4><strong>{{ nameUser }}</strong></h4>
          </div>
          <div
            style="width: 50%;"
          >
            <h5>{{$t('campaigns.dateApplicant')}}:</h5>
            <h4><strong>{{ fecha }}</strong></h4>
          </div>
        </div>
        <div
          class="mt-3 p-1"
        >
          <h5 class="text-left"><strong>{{$t('campaigns.imagesCampaging')}}</strong></h5>
          <div
            style="width: 100%; display: flex;"
          >
            <div style="width: 98%; margin-right: 4%;">
              <label for="wildcard">{{$t('campaigns.uploadLogo')}}</label>
              <b-form-file
                id="logo"
                ref="logo"
                accept=".jpeg, .jpg, .png"
                v-on:change="handleFileUpload()"
                :placeholder="$t('campaigns.selectLogo')"
                :browse-text="$t('campaigns.search')"
              />
            </div>
            <div style="width: 98%">
              <label for="wildcard">{{$t('campaigns.uploadCover')}}</label>
              <b-form-file
                id="cover"
                ref="cover"
                accept=".jpeg, .jpg, .png"
                v-on:change="handleFileUpload()"
                :placeholder="$t('campaigns.selectCover')"
                :browse-text="$t('campaigns.search')"
              />
            </div>
          </div>
        </div>
        <div
          class="mt-3 p-1"
        >
          <h5
            class="text-left font-weight-bolder"
          >{{$t('campaigns.campaignName')}}</h5>
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="name"
              v-model="name"
              ref="name"
              :placeholder="$t('campaigns.campaignName')"
              autocomplete="off"
              maxlength="100"
            />
          </b-input-group>
        </div>
        <div
          class="p-1"
          v-if="role !== 'realty'"
        >
          <h5
            class="text-left font-weight-bolder"
          >{{$t('campaigns.brand')}}</h5>
          <b-form-group>
            <v-select
              v-model="selectedBrand"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="nameBrand"
            />
          </b-form-group>
        </div>
        <div
          class="p-1"
        >
          <h5
            class="text-left font-weight-bolder"
          >{{$t('campaigns.budget')}}</h5>
              <b-input-group
              prepend="$"
              >
                  <cleave
                  v-model="budget"
                  ref="budget"
                  class="form-control"
                  :raw="false"
                  :options="options.number"
                  placeholder="10,000"
                  :maxlength="10"
                  @input="validateBudget"
                  />
              </b-input-group>
        </div>
        <div class="p-1">
          <h5
            class="text-left font-weight-bolder"
          >{{$t('campaigns.campaignValidity')}}</h5>
          <div
            style="width: 100%; display: flex;"
          >
              <div style="width: 48%; margin-right: 4%;">
                  <label for="fecha-inicio">{{$t('campaigns.initDate')}}</label>
                  <b-form-datepicker
                  id="fecha-inicio"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="init_date"
                  class="mb-1"
                  :placeholder="$t('campaigns.initDate')"
                  />
              </div>
              <div style="width: 48%">
                  <label for="fecha-final">{{$t('campaigns.endDate')}}</label>
                  <b-form-datepicker
                  id="fecha-final"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  v-model="end_date"
                  class="mb-1"
                  :placeholder="$t('campaigns.endDate')"
                  />
              </div>
          </div>
        </div>
        <div
        class="p-1"
        >
          <h5
            class="text-left font-weight-bolder"
          >{{$t('campaigns.description')}}</h5>
          <b-form-group>
              <component
                :is="quill_editor"
                id="quil-content"
              v-model="description"
              :options="editorOption[0]"
              class="border-bottom-0"
              style="max-width: 580px;"
              />
              <div
              id="quill-toolbar0"
              class="d-flex justify-content-end border-top-0"
              >
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
              </div>
          </b-form-group>
        </div>
        <div
        class="p-1"
        v-if="role !== 'realty'"
        >
          <h5
            class="text-left font-weight-bolder"
          >{{$t('campaigns.message')}}</h5>
          <b-form-checkbox
            v-model="showCustomMessage"
            class="custom-control-primary text-left mt-1 mb-1"
          >
            {{$t('campaigns.showMessage')}}
          </b-form-checkbox>
          <b-form-group>
              <component
                :is="quill_editor"
                id="quil-content"
                v-model="message"
                :options="editorOption[1]"
                class="border-bottom-0"
                style="max-width: 580px;"
              />
              <div
              id="quill-toolbar1"
              class="d-flex justify-content-end border-top-0"
              >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
              </div>
          </b-form-group>
        </div>
      </div>

      <div
        class="mr-2 ml-2"
        v-for="(item, index) in guidelines"
        :key="index"
      >
        <div class="d-flex align-items-center">
          <h4 class="text-left font-weight-bolder mb-0">{{item.front_name}}</h4>
          <b-button
            variant="flat-danger"
            class="btn-icon"
            @click="deleteGuideline(index)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
        <b-form-checkbox
          :checked="item.show_link"
          :disabled="true"
          class="custom-control-primary text-left mt-1 mb-1"
        >
          {{$t('campaigns.showMessage')}}
        </b-form-checkbox>
        <b-form-group>
          <div v-html="item.form_value"></div>
        </b-form-group>
      </div>

      <modal-brandme size="xl" :show_modal="show_modal" :key="change_modal">
        <template #title>{{$t('campaigns.newRequirement')}}</template>
        <template #body>
          <new-requirement :campaign_uuid="$route.params.uuid" @closeModal="closeModalCustomField" @dataCustomField="dataCustomField"></new-requirement>
        </template>
      </modal-brandme>

      <div class="d-flex justify-content-center mb-1">
        <b-button variant="success" @click="showModalAddCustom()"> {{$t('campaigns.addRequirement')}}</b-button>
      </div>
      <div class="p-1 border-top text-danger cursor-pointer footerCampaign">
          <b-button
          @click="createdList()"
          variant="primary"
          class="footerCampaign"
          :disabled="creatingCampaign"
          >
          {{$t('campaigns.createCampaign')}}
          </b-button>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCardText, BInputGroup, BFormInput, BInputGroupPrepend, BFormDatepicker, BButton, BFormGroup, BFormFile, BFormCheckbox, BOverlay
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import service from '@/services/campaign'
import { hasPermission } from '@/libs/utils/user';
import ModalBrandme from '../components/modal/ModalBrandme.vue'
import newRequirement from '../campaings/newRequirement.vue'
import { showToast } from '@/libs/utils/icons'

export default {
  components: {
    BCardText,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BFormDatepicker,
    BButton,
    BFormGroup,
    Cleave,
    BFormFile,
    vSelect,
    BFormCheckbox,
    BOverlay,
    ModalBrandme,
    newRequirement
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      hasPermission,
      showToast,
      show: false,
      init_date: '',
      end_date: '',
      description: '',
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
        }
      },
      fecha:'',
      logo:'',
      cover:'',
      name:'',
      brand:'',
      selectedBrand: {title: this.$t('campaigns.selectBrand'), uuid: ''},
      nameBrand: [],
      budget:'',
      customMessage: '',
      showCustomMessage: true,
      message: '',
      creatingCampaign: this.disabledAddCampaign(),
      role: 'brand',
      show_modal: false,
      change_modal: true,
      guidelines: [],
      quill_editor: null,
    }
  },
  created () {
    const data_user = JSON.parse(localStorage.getItem('userData'));

    this.role = data_user.main_group.name;

    const d = new Date()
    this.fecha = `${d.getFullYear()} / ${(d.getMonth() + 1)} / ${d.getDate()}`
    this.getBrands()
    this.getMessageInvitation()
  },
  async mounted() {
    const vue_quill_editor = (await import('vue-quill-editor'));
    this.quill_editor = vue_quill_editor.quillEditor;
    await import('quill/dist/quill.core.css');
    await import('quill/dist/quill.snow.css');
    await import('quill/dist/quill.bubble.css');
  },
  setup (props, context) {
    // console.log(props)
    // j es la cantidad de cajas que quieres
    const j = 2
    let i = 0
    const editorOption = []
    do {
      const value = {
        modules: {
          toolbar: `#quill-toolbar${i}`
        },
        placeholder: context.parent.$t('campaigns.writeHere')
      }
      editorOption.push(value)
      i += 1
    } while (i < j)
    return {
      editorOption
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    userData () {
      return JSON.parse(localStorage.getItem('userData'))
    },
    nameUser () {
      return `${this.userData.first_name} ${this.userData.last_name}`
    },
    is_free() {
      return this.userData.current_plan.name === 'Free'
    }
  },
  methods: {
    validateBudget() {
      const maxBudget = 10000000;
      const enteredBudget = parseInt(this.budget.replace(/,/g, ''), 10);
      if (enteredBudget > maxBudget) {
        this.budget = '10,000,000';
      }
    },
    formatDate(date) {
      const fecha = date.split("-")    
      return parseInt(fecha[1]) > 12 ? `${fecha[0]}-${fecha[2]}-${fecha[1]}` : `${fecha[0]}-${fecha[1]}-${fecha[2]}`

    },
    deleteGuideline(index) {
      this.guidelines.splice(index, 1);
    },
    dataCustomField(data) {
      this.closeModalCustomField()
      this.guidelines.push(data)
    },
    closeModalCustomField() {
      this.show_modal = false
      this.change_modal = !this.change_modal
    },
    showModalAddCustom() {
      this.show_modal = true
      this.change_modal = !this.change_modal
    },
    handleFileUpload () {
      this.logo = this.$refs.logo.files[0]
      this.cover = this.$refs.cover.$el.firstChild.files[0]
    },
    getBrand (info) {
      const result = []
      info.forEach(i => {
        let obj = []
        obj = {
          title: i.name,
          uuid: i.uuid
        }
        result.push(obj)
      })
      return result
    },
    createdList () {
      if (this.disabledAddCampaign()) return;

      this.creatingCampaign = true;

      const brand = this.selectedBrand?.uuid || ''

      if (this.description === '' || this.description === null) {
        this.creatingCampaign = false;
        return showToast(this, this.$t('campaigns.aDescription'), this.$t('campaigns.aDescriptionText'), 'AlertCircleIcon');
      }
      if (this.cover === '' || this.cover === null || this.cover === undefined) {
        return showToast(this, this.$t('campaigns.aCover'), this.$t('campaigns.aCoverText'), 'AlertCircleIcon');
      }
      if (this.logo === '' || this.logo === null || this.logo === undefined) {
        return showToast(this, this.$t('campaigns.aLogo'), this.$t('campaigns.aLogoText'), 'AlertCircleIcon');
      }
      if (this.name === '' || this.name === null) {
        return showToast(this, this.$t('campaigns.aName'), this.$t('campaigns.aNameText'), 'AlertCircleIcon');
      }
      if (this.budget === '' || this.budget === null) {
        return showToast(this, this.$t('campaigns.aPrice'), this.$t('campaigns.aPriceText'), 'AlertCircleIcon');
      }
      if (this.init_date === '' || this.init_date === null) {
        this.creatingCampaign = false;
        return showToast(this, this.$t('campaigns.aInit'), this.$t('campaigns.aInitText'), 'AlertCircleIcon');
      } else {
        this.init_date = this.formatDate(this.init_date);
        this.creatingCampaign = false;
      }

      const dateInit = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateInit.test(this.init_date)) {
        this.creatingCampaign = false;
        return showToast(this, this.$t('campaigns.invalidFormat'), this.$t('campaigns.invalidFormatText'), 'AlertCircleIcon');
      }

      if (this.end_date === '' || this.end_date === null) {
        this.creatingCampaign = false;
        return showToast(this, this.$t('campaigns.aEnd'), this.$t('campaigns.aEndText'), 'AlertCircleIcon');
      } else {
        this.end_date = this.formatDate(this.end_date);
        this.creatingCampaign = false;
      }

      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(this.end_date)) {
        this.creatingCampaign = false;
        return showToast(this, this.$t('campaigns.invalidFormat'), this.$t('campaigns.invalidFormatText'), 'AlertCircleIcon');
      }

      if (this.message === '' || this.message === null) {
        this.creatingCampaign = false;
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-center',
            props: {
              title: this.$t('campaigns.aMessage'),
              icon: 'AlertCircleIcon',
              text: this.$t('campaigns.aMessageText'),
              variant: 'danger'
            }
          })
        })
        return false
      }

      const custom_fields = JSON.stringify(JSON.stringify(this.guidelines))

      this.show = true
      const precio = this.budget.replace(/,/g, '')
      const formData = new FormData()
      formData.append('cover', this.cover)
      formData.append('logo', this.logo)
      formData.set('name', this.name)
      formData.set('brand', brand)
      formData.set('budget', precio)
      formData.set('init_date', this.init_date)
      formData.set('end_date', this.end_date)
      formData.set('description', this.description)
      formData.set('brand_reference', brand)
      formData.set('custom_fields', custom_fields)
      if (this.message !== '') {
        formData.set('show_custom_message', this.showCustomMessage)
        formData.set('custom_message', this.message)
      } else {
        formData.set('show_custom_message', false)
        formData.set('custom_message', '')
      }
      service.createCampaign(formData)
        .then(result => {
          this.show = false
          this.listCreatedConfirmation(result)
          this.creatingCampaign = false;
          if (this.is_free) this.$emit('update_campaigns_avaible')
        })
        .catch(() => {
          this.show = false
          console.log('FAILURE!!')
          this.creatingCampaign = false;
        })
    },
    resetForm() {
      this.$refs.cover.files = []
      this.cover = null;
      this.$refs.logo.files = []
      this.logo = null;
      this.name = this.description = this.budget = this.init_date = this.end_date = ''
      this.selectedBrand = {title: this.$t('campaigns.selectBrand'), uuid: ''};
      this.getMessageInvitation()
    },
    listCreatedConfirmation(campaign) {
      if (campaign && campaign.uuid) {
        this.$emit('campaignCreated', campaign);
        this.$swal({
          title: this.$t('campaigns.aCreated'),
          text: this.$t('campaigns.aCreatedText'),
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false,
          heightAuto: false,
        }).then(() => {
          this.resetForm();
        });
      } else {
        this.$emit('campaignCreated', campaign);
        this.$swal({
          title: this.$t('campaigns.errorCreated'),
          text: this.$t('campaigns.errorCreatedText'),
          variant: 'danger',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false,
          heightAuto: false,
        }).then(() => {
          this.resetForm();
        });
      }
    },
    acceptListCreated () {
      location.reload()
    },
    getMessageInvitation() {
      service.getMessageInvitation()
        .then(response => {
          this.message = response.message;
        })
    },
    getBrands () {
      service.getCampaignBrands()
        .then(response => {
          response.results.map((item) => {
            this.nameBrand.push({title: item.name, uuid: item.uuid})
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    disabledAddCampaign() {
      return !hasPermission('add_campaign')
    }
  }
}
</script>

<style>
  .footerCampaign{
      width: 100%;
  }
  .d-block{
    text-align: left;
  }
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + [id^=quill-toolbar] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>